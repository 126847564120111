/* Light Theme - Global Styles */
html, body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    background-color: #F9F9F9;  /* Light grayish-white background */
    color: #333333; /* Dark text for contrast */
    text-align: center;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}

/* Main container */
.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    max-width: 600px;
    padding: 20px;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Ensures spacing from the top */
}

/* Ensure logo image fits well */
.logo img {
    width: 100px; /* Adjust as needed */
    height: auto;
    max-width: 100%;
    object-fit: contain;
}

/* Fix mobile layout */
@media (max-width: 768px) {
    html, body {
        height: auto; /* Prevents cut-off issues */
        min-height: 100vh;
        padding-top: 20px; /* Adds space at the top */
    }

    .container {
        width: 95%; /* Ensures it fits smaller screens */
        padding: 15px;
    }

    .logo img {
        width: 80px; /* Slightly smaller for mobile */
    }
}
/* Typography */
.title {
    font-size: 2.4em;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0 15px;
    color: #222222; /* Darker gray for headings */
}

.subtitle {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 8px;
    padding: 0 15px;
    color: #555555; /* Medium-dark gray */
}

.message {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 20px;
    color: #666666;
    padding: 0 15px;
}

/* Buttons */
.buttons {
    margin-top: 20px;
    display: flex;
    gap: 15px;
}

.store-button {
    width: 160px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    transition: transform 0.2s ease-in-out;
}

.store-button:hover {
    transform: scale(1.05);
}

/* Footer */
.footer {
    width: 100%;
    text-align: center;
    color: #777777;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    padding: 20px 0;
}

/* Privacy Policy Link */
.privacy-policy {
    margin-top: 10px;
    color: #666666;
    font-size: 1em;
    margin-bottom: 10px;
    transition: color 0.3s ease-in-out;
}

.privacy-policy:hover {
    color: #0077cc; /* Blue hover effect */
}

/* Social Media Section */
.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
}

.social-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.2s ease-in-out;
}

.social-icon:hover {
    transform: scale(1.1);
}

.instagram {
    background-image: url('../public/assets/instagram.svg');
}

.twitter {
    background-image: url('../public/assets/twitter.svg');
    width: 25px;
    height: 25px;
}

.youtube {
    background-image: url('../public/assets/youtube.svg');
}

.footer-divider {
    width: 60%;
    border: 0;
    height: 1px;
    background-color: #DDDDDD;
    margin: 15px 0;
}

/* General advertising text */
.advertising-text {
    font-size: 1.3em;
    font-weight: 500;
    color: #444444;
    margin: 20px 0;
    max-width: 600px;
    line-height: 1.5;
}

/* Background highlight for key words */
.highlight-bg {
    background-color: #DDEEFF; /* Light blue for key highlights */
    padding: 2px 5px;
    border-radius: 4px;
}

/* Underline highlight */
.highlight-underline {
    text-decoration: underline;
    font-weight: bold;
    color: #0077cc; /* Blue color for emphasis */
}
